import { useEffect, useState } from 'react';

import {
  Header,
  LogoPrimaryCP,
  Drawer,
  useDrawer,
  NavbarVertical,
  Button,
} from '@consumidor-positivo/aurora';

import './style.scss';

const headerData = {
  links: [
    {
      name: 'Início',
      href: '/',
    },
    {
      name: 'Quem somos',
      href: '/quem-somos',
    },
    {
      name: 'Score',
      href: '/score?utm_source=cross-sell&utm_medium=positive-consumer&utm_campaign=header-home&utm_content=logged-out',
    },
    {
      name: 'Cadastro Positivo',
      href: '/cadastro-positivo?utm_source=cross-sell&utm_medium=positive-consumer&utm_campaign=header-home&utm_content=logged-out',
    },
    {
      name: 'Crédito',
      dropdown: [
        {
          name: 'Cartões de crédito',
          href: '/cartao-de-credito?utm_source=cross-sell&utm_medium=positive-consumer&utm_campaign=header-home&utm_content=logged-out',
        },
        {
          name: 'Empréstimo',
          href: '/emprestimo?utm_source=cross-sell&utm_medium=positive-consumer&utm_campaign=header-home&utm_content=logged-out',
        },
      ],
    },
    {
      name: 'Blog',
      href: '/blog?utm_campaign=header-home&utm_content=logged-out&utm_medium=positive-consumer&utm_source=cross-sell',
    },
  ],
  ctas: [
    {
      name: 'Cadastrar',
      href: 'https://app.acordocerto.com.br/cadastro',
      type: 'outlined',
    },
    {
      name: 'Consultar CPF',
      href: 'https://www.acordocerto.com.br/consultar-o-cpf',
      type: 'primary',
    },
  ],
};

const AuroraHeader = () => {
  const { drawerOpen, handleOpenDrawer } = useDrawer({ menu: false });

  const [currentPath, setCurrentPath] = useState<string | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentPath(window.location.pathname);
    }
  }, []);

  if (currentPath === null) {
    return null;
  }

  return (
    <Header.Root>
      <Header.Logo
        renderDesktop={<LogoPrimaryCP />}
        renderMobile={<LogoPrimaryCP />}
      />
      <Header.Navigation>
        <Header.Navbar
          data={headerData.links}
          renderItem={(item) => {
            let isActive = false;

            if (item.dropdown) {
              isActive = item.dropdown.some(
                (dropdownItem) =>
                  currentPath ===
                  new URL(dropdownItem.href ?? '', window.location.origin)
                    .pathname
              );
            } else {
              const itemPath = new URL(item.href ?? '', window.location.origin)
                .pathname;
              isActive = currentPath === itemPath;
            }

            return (
              <Header.NavbarLink
                key={item.name}
                active={isActive}
                href={item.href}
                dropdown={item.dropdown}
                name={item.name}
                onClick={item.onClick}
              />
            );
          }}
        />

        <Header.Actions>
          <Button
            as="a"
            href="https://app.consumidorpositivo.com.br/"
            target="_blank"
          >
            Consultar CPF
          </Button>
          <Header.Hamburger onClick={() => handleOpenDrawer('menu')} />
        </Header.Actions>
      </Header.Navigation>
      <Drawer
        isOpen={drawerOpen.menu}
        handleOpen={() => handleOpenDrawer('menu')}
        renderHeader={<LogoPrimaryCP />}
        renderContent={
          <NavbarVertical
            data={headerData.links}
            renderItem={(link) => {
              let isActive = false;

              if (link.dropdown) {
                isActive = link.dropdown.some(
                  (dropdownItem) =>
                    currentPath ===
                    new URL(dropdownItem.href ?? '', window.location.origin)
                      .pathname
                );
              } else {
                const itemPath = new URL(
                  link.href ?? '',
                  window.location.origin
                ).pathname;
                isActive = currentPath === itemPath;
              }

              return (
                <NavbarVertical.Link
                  key={link.name}
                  active={isActive}
                  href={link.href}
                  dropdown={link.dropdown}
                  name={link.name}
                />
              );
            }}
            renderActions={() =>
              headerData.ctas.map((cta, index) => (
                <Button
                  key={index}
                  expand="x"
                  as="a"
                  href={cta.href}
                  type={cta.type as 'outlined' | 'primary'}
                >
                  {cta.name}
                </Button>
              ))
            }
          />
        }
      />
    </Header.Root>
  );
};

export default AuroraHeader;
